import React, { useState, useRef } from "react";
import { Modal, Box, Button, IconButton, TextField, Chip } from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import DeleteIcon from "@mui/icons-material/Delete";
import {useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

interface SignatureModalProps {
  isModalOpen: boolean;
  handleModalClose: () => void;
  consentBundleId: string | null;
  clinicanModalId: string | null;
  state: string | null;
}

const SignatureModal: React.FC<SignatureModalProps> = ({ isModalOpen, handleModalClose ,consentBundleId,clinicanModalId,state}) => {
  const [activeInput, setActiveInput] = useState<string>("signature");
  const [userFullName, setUserFullName] = useState<string>("");
  const [isSignatureCaptured, setIsSignatureCaptured] = useState<boolean>(false);
  const mySignature = useRef<any>(null);
  const [isImageVisible, setIsImageVisible] = useState(true);
const [isFormSuccess, setIsFormSuccess] = useState(false);

  const eraseSignature = () => {
    const signature = mySignature.current;
    setIsSignatureCaptured(false);
    signature?.clear();
  };

  const handleChipClick = (inputType: string) => {
    setActiveInput(inputType);
    if (inputType === "signature") {
      setIsSignatureCaptured(false);
    }
  };

  const handleNameSave = () => {
    console.log("Name saved:", userFullName);
    setActiveInput("");
  };

  const handleSignatureSave = () => {
    if (mySignature.current) {
      const signatureData = mySignature.current.toDataURL();
      signLegacy.mutate({ image: signatureData });
    }
    setActiveInput("");
    setIsImageVisible(true);
  };




  const signLegacy = useMutation(
    async ({ image, name }: { image?: string; name?: string }) => {
      const token = axios.defaults.headers.common.Authorization;
      const formattedData = image ? { image: image } : { image: name };

      // if (state === "header") {
      //   console.log("in", state);
      //   try {
      //     const { data } = await axios.get(`/user`);
      //     const clinicanId = data[1].medicube_user_id;
      //     console.log("User data:", clinicanId);

      //     return axios.post(
      //       `/practitioner/${clinicanId}/save_signature`,
      //       formattedData,
      //       {
      //         headers: {
      //           Authorization: token,
      //         },
      //       }
      //     );
      //   } catch (error) {
      //     console.error("Error fetching user data:", error);
      //   }
      //   return ;
      // }

      // console.log("Signature uploaded successfully", consentBundleId);
      // console.log("clinicanModalId", clinicanModalId);

      return axios.post(
        `/practitioner/${clinicanModalId}/save_signature`,
        formattedData,
        {
          headers: {
            Authorization: token,
          },
        }
      );
    },
    {
      onSuccess: () => {
        setIsFormSuccess(true);
        console.log("Signature saved successfully.");
        handleModalClose();
      },
      onError: (error) => {
        console.error("Error saving signature:", error);
      },
    }
  );
  
  

  return (
    <Modal open={isModalOpen} onClose={handleModalClose} aria-labelledby="modal-signature">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: "600px" },
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "8px",
        }}
      >
        <h2 id="modal-signature">Sign Consent</h2>

        <Chip
          label="Signature"
          variant={activeInput === "signature" ? "filled" : "outlined"}
          onClick={() => handleChipClick("signature")}
          sx={{
            mr: 2,
            fontSize: "1.2rem",
            padding: "10px 20px",
            backgroundColor: activeInput === "signature" ? "#FFA500" : "transparent",
            color: activeInput === "signature" ? "white" : "inherit",
          }}
        />
       

        {activeInput === "signature" && (
          <div
            style={{
              border: "2px solid #243474",
              margin: "10px 0",
              padding: "10px",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <SignatureCanvas
              penColor="#243474"
              ref={mySignature}
              canvasProps={{ width: 600, height: 300, className: "signatureCanvas" }}
              onBegin={() => setIsSignatureCaptured(true)}
            />
          </div>
        )}

        {activeInput === "name" && (
          <TextField
            label="Full Name"
            variant="outlined"
            fullWidth
            value={userFullName}
            onChange={(e) => setUserFullName(e.target.value)}
            sx={{ mt: 2 }}
          />
        )}

        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
          <Button variant="outlined" color="error" onClick={handleModalClose}>
            Close
          </Button>

          <div>
            {activeInput === "signature" && (
              <>
                <IconButton onClick={eraseSignature}>
                  <DeleteIcon />
                </IconButton>
                <Button
                  variant="contained"
                  color="success"
                  size="large"
                  onClick={handleSignatureSave}
                  disabled={!isSignatureCaptured}
                >
                  Save Signature
                </Button>
              </>
            )}

            {activeInput === "name" && (
              <Button
                variant="contained"
                color="success"
                size="large"
                onClick={handleNameSave}
                disabled={!userFullName}
              >
                Save Name
              </Button>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default SignatureModal;
