import React, { useState, useEffect } from "react";
import styles from "./Templates.module.scss";
import TemplateList from "./TemplateList";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import queryString from "query-string";
import { isEmpty } from "lodash";
import TemplateEditor from "./TemplateEditor";
import { Button } from "@mui/material";
import CreateConsentTemplateForm from "./CreateConsentTemplateForm";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TemplatesComponent from "../TemplateModel";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { Modal, CircularProgress, Box, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import {
  Tooltip,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const Templates = () => {
  const [templates, setTemplates] = useState([]);
  const [nonDefaultTemplates, setNonDefaultTemplates] = useState([]);
  const [globaleTemplates, setGlobalTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [category, setCategory] = useState("All");
  const [categoryId, setCategoryId] = useState(null);
  const [search, setSearch] = useState("");
  const [shouldFetch, setShouldFetch] = useState(true);
  const [dropdownClicked, setDropdownClicked] = useState(false);
  const [isOpenCreateConsentModal, setIsOpenCreateConsentModal] =
    useState(false);
  const [treatmentPlans, setTreatmentPlans] = useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const [isImageModalOpen, setIsImageModalOpen] = React.useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);

  const { data: isAdminData, isLoading: isAdminLoading } = useQuery(
    ["isAdmin"],
    async () => {
      const { data } = await axios.get("/user/is_admin");
      return data;
    }
  );

  useEffect(() => {
    const fetchTreatmentPlans = async () => {
      try {
        const { data } = await axios.get(`/treatment`);
        const plans = data.treatments.map((plan) => ({
          ...plan,
          label: plan.description,
          value: plan.id,
        }));
       // console.log(data);

        setTreatmentPlans(plans);
      } catch (error) {
        console.error("Error fetching treatment plans:", error);
      }
    };
    fetchTreatmentPlans();
  }, []);

  const { data: categoryData, isLoading: isCategoryLoading } = useQuery(
    ["categories"],
    async () => {
      const { data } = await axios.post(`/treatment_category`);
      return data;
    },
    {
      enabled: dropdownClicked,
    }
  );

  const { data, isLoading, error, refetch } = useQuery(
    ["consentClauses", categoryId, search],
    async () => {
      const params = { category: categoryId || undefined, search };
      const stringified = queryString.stringify(params);
      const { data } = await axios.get(`/consent_clause?${stringified}`);
      //console.log(data)
      setTemplates(data.consent_form_templates);
      setNonDefaultTemplates(data.non_default_consent_form_templates);
      setGlobalTemplates(data.global_templates);
      return data;
    },
    {
      enabled: true,
      onSuccess: (data) => {
        if (isEmpty(selectedTemplate) && data?.consent_form_templates?.length) {
          setSelectedTemplate(data.consent_form_templates[0]);
        }
      },
    }
  );

  const handleSelect = (template) => {
    setSelectedTemplate(template);
    setCategory(template.category_name || "All");
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setCategory(selectedCategory);
    const selectedCategoryData = categoryData.find(
      (cat) => cat.category_name === selectedCategory
    );
    setCategoryId(selectedCategoryData ? selectedCategoryData.id : null);
  };

  const handleDropdownClick = () => {
    setDropdownClicked(true);
  };

  const handleCreateNewTemplate = async (newTemplateId) => {
    const { data } = await axios.get(`/consent_clause?=`);
    const newTemplate = [
      ...(data?.consent_form_templates || []),
      ...(data?.non_default_consent_form_templates || []),
    ].find((template) => template.id === newTemplateId);

    if (newTemplate) {
      setSelectedTemplate(newTemplate);
      setTemplates(data.consent_form_templates);
      setNonDefaultTemplates(data.non_default_consent_form_templates);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 10000);
    return () => clearInterval(interval);
  }, [refetch]);

  const getPracticeConsentClauseData = async () => {
    try {
      const { data } = await axios.get("/user");
      const practiceId = data[1].medicube_practice_id;
      const permisionlevel = data[1].permission_level;
      const response = await axios.get(
        `/practice/${practiceId}/get_practice_consent_clause_data`
      );
      setPermisionlevel(permisionlevel);
      setLogoData(response.data);
    } catch (error) {
      console.error("Error fetching practice consent clause data:", error);
    } finally {
      setIsModalOpen(false);
    }
  };

  const closeModal = () => {
    getPracticeConsentClauseData();
    setIsModalOpen(false);
  };

  return (
    <div className={styles.templatesPage}>
      {/* left panel */}
      <div className={styles.sidePanel}>
        <h1 className={styles.header}>My Templates</h1>
        <div className={styles.searchFilter}>
          <input
            type="text"
            placeholder="Search"
            className={styles.searchInput}
            value={search}
            onChange={handleSearchChange}
          />
          <select
            className={styles.categoryFilter}
            value={category}
            onChange={handleCategoryChange}
            onClick={handleDropdownClick}
          >
            <option value="All">All</option>
            {isCategoryLoading ? (
              <option disabled>Loading categories...</option>
            ) : categoryData && Array.isArray(categoryData) ? (
              categoryData.map((cat) => (
                <option key={cat.id} value={cat.category_name}>
                  {cat.category_name}
                </option>
              ))
            ) : (
              <option disabled>No categories available</option>
            )}
          </select>
        </div>

        {!isAdminLoading && isAdminData && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "5px",
            }}
          >
            <Button
              type="button"
              variant="contained"
              onClick={() => setIsModalOpen(true)}
              style={{
                fontSize: "15px",
                padding: "12px 30px",
                borderRadius: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Upload Practice Name and Logo
              <CloudUploadIcon
                style={{
                  marginLeft: "10px",
                  fontSize: "20px",
                }}
              />
            </Button>
          </div>
        )}

        <Modal open={isModalOpen} onClose={closeModal}>
          <Box>
            <TemplatesComponent closeModal={closeModal} />
          </Box>
        </Modal>

        <TemplateList
          defaultTemplates={templates}
          nonDefaultTemplates={nonDefaultTemplates}
          globalTemplates={globaleTemplates}
          onSelect={handleSelect}
          isLoading={isLoading}
          selectedTemplateId={selectedTemplate ? selectedTemplate.id : null}
        />
      </div>

      {/* right panel */}
      <div className={styles.mainContent}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginLeft: "auto",
            marginBottom: "26px",
            position: "relative",
            flexWrap: "wrap",
          }}
        >
          {/* tooltip with steps */}

          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              type="button"
              variant="contained"
              onClick={() => setIsOpenCreateConsentModal(true)}
              style={{
                fontSize: "18px",
                padding: "12px 24px",
                height: "48px",
                borderRadius: "10px",
              }}
            >
              + Create New Consent Clause
            </Button>
          </div>
        </div>

        <div className={styles.banner}>
          <img
            src="\workflow.png"
            alt="Step-by-step instructions"
            style={{
              width: "690px",
              height: "auto",
            }}
          />
        </div>

        {/* <div className={styles.banner}>
  <img
    src="\medicube.gif" 
    alt="Step-by-step instructions"
    style={{
      width: "690px", 
      height: "auto", 
    }}
  />
</div> */}

        <TemplateEditor
          selectedTemplate={selectedTemplate}
          categoryData={categoryData}
          onSave={() => {}}
          defaultCategory={category}
        />

        <CreateConsentTemplateForm
          isOpen={isOpenCreateConsentModal}
          close={() => setIsOpenCreateConsentModal(false)}
          categories={categoryData || []}
          treatmentPlans={treatmentPlans}
          setCurrentTemplate={handleCreateNewTemplate}
        />
      </div>
    </div>
  );
};

export default Templates;
