import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions,Popper , TextField, MenuItem, Box ,Typography, Card, CardMedia, CardContent } from '@mui/material';
import { Field, Form } from 'react-final-form';
import { Select as RffSelect } from 'mui-rff';
import axios from 'axios';
import { toast } from 'react-toastify';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from "@mui/material/Tooltip";


const API_KEY = 'AIzaSyByI_l6XffzkdFkoTIOy6tThHkAcDIQNL4';

const isValidYouTubeUrl = (url) => {
  const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/|v\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})\b/;
  return pattern.test(url);
};

const validate = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = 'Required';
  }
  if (!values.url) {
    errors.url = 'Required';
  } else if (!isValidYouTubeUrl(values.url)) {
    errors.url = 'Invalid YouTube URL';
  }
  return errors;
};

const getVideoDetails = async (videoId) => {
  const url = `https://www.googleapis.com/youtube/v3/videos?part=snippet,contentDetails&id=${videoId}&key=${API_KEY}`;
  
  try {
    const response = await fetch(url);
    const data = await response.json();
    if (data.items.length > 0) {
      const videoData = data.items[0];
      const title = videoData.snippet.title;
      const description = videoData.snippet.description;
      const thumbnail = videoData.snippet.thumbnails.high.url;
      const durationISO = videoData.contentDetails.duration;
      
      const duration = parseDuration(durationISO);

      return { title, description, thumbnail, duration };
    } else {
      throw new Error('Video not found');
    }
  } catch (error) {
    console.error('Error fetching video details:', error);
    return null;
  }
};


const extractVideoId = (url) => {
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

const parseDuration = (isoDuration) => {
  const match = isoDuration.match(/PT(?:([\d]+)H)?(?:([\d]+)M)?(?:([\d]+)S)?/);
  const hours = parseInt(match[1], 10) || 0;
  const minutes = parseInt(match[2], 10) || 0;
  const seconds = parseInt(match[3], 10) || 0;

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

const PlaylistModal = ({ open, onClose, onSubmit, playlistId, currentCategory }) => {
  
  const initialFormValues = {
    title: '',
    description: '',
    url: '',
    platform: 'youtube',
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [videoDetails, setVideoDetails] = useState({ title: '', description: '', thumbnail: '', duration: '' });
  const [s3Videos, setS3Videos] = useState([]);
  const [hoveredVideo, setHoveredVideo] = useState<number | null>(null);
  const [selectedVideos, setSelectedVideos] = useState<number[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);


  useEffect(() => {
    if (!open) {
      setFormValues(initialFormValues);
      setVideoDetails({ title: '', description: '', thumbnail: '', duration: '' });
    }
  }, [open]);

  const handleScrubVideoDetails = async (url, form) => {
    const videoId = extractVideoId(url);
    if (videoId) {
      const details = await getVideoDetails(videoId);
      if (details) {
        setVideoDetails({
          title: details.title,
          description: details.description,
          thumbnail: details.thumbnail,
          duration: details.duration,
        });
        form.change('title', details.title);
        form.change('description', details.description);
        
      } else {
        toast.error("Failed to fetch video details.");
      }
    } else {
      toast.error("Invalid YouTube URL.");
    }
  };
  const toggleSelection = (videoUrl: string) => {
    
    setSelectedVideos((prevSelected) => {
      const updatedSelection = prevSelected.includes(videoUrl)
        ? prevSelected.filter((url) => url !== videoUrl)
        : [...prevSelected, videoUrl];
  
      return updatedSelection;
    });
  };
  useEffect(() => {

  }, [selectedVideos]);

  const handleSubmitS3 = async (values) => {
    const selectedVideoData = s3Videos
      .filter(video => selectedVideos.includes(video.url))
      .map(({ id, ...video }) => ({
        ...video,
        type: 'mp4',
        platform: 's3',
      }));
  
    const updatedVideos = currentCategory.videos.map(({ thumbnail, ...video }) => ({
      ...video,
      type: 'mp4',
    }));
  
    const playlistPayload = [...updatedVideos, ...selectedVideoData];
   
  
    const response = await axios.put(`playlist/${playlistId}/save_playlist_items`, playlistPayload);
    if (response.status === 200) {
      const result = await response.data;
      if (result === true) {
        toast.success("Playlist item saved successfully");
      } else {
        toast.error("Failed to save playlist item.");
      }
    } else {
      toast.error("Failed to save playlist item.");
    }
  
    selectedVideoData.forEach(video => {
      onSubmit({
        ...values,
        duration: video.duration,
        thumbnail: video.thumbnail,
        title: video.title,
        url:video.url,
      });
    });

    selectedVideos.length = 0;
  };

  const handleSubmit = async (values) => {
    const videoId = extractVideoId(values.url);

    if (videoId) {
      try {
        const newVideo = {
          title: values.title || videoDetails.title,
          description: values.description || videoDetails.description,
          url: values.url,
          platform: values.platform,
          duration: videoDetails.duration,
          type: 'mp4',
        };

        const updatedVideos = currentCategory.videos.map(({ thumbnail, ...video }) => ({
          ...video,
          type: 'mp4',
        }));

        updatedVideos.push(newVideo);
        const payload = updatedVideos;
       
        const response = await axios.put(`playlist/${playlistId}/save_playlist_items`, payload);

        if (response.status === 200) {
          const result = await response.data;
          if (result === true) {
            toast.success("Playlist item saved successfully");
          } else {
            toast.error("Failed to save playlist item.");
          }
        } else {
          toast.error("Failed to save playlist item.");
        }
        onSubmit({ ...values, duration: videoDetails.duration , thumbnail: videoDetails.thumbnail});
      } catch (error) {
        toast.error("Failed to save playlist item.");
      }
    } else {
      toast.error("Invalid YouTube URL");
    }
  };

  const getS3Data = async () => {
    try {
      const response = await axios.get(`/playlist/global/get_global_playlist_items`);
      setS3Videos(response.data); 
    } catch (error) {
      console.error("Error fetching S3 playlist items:", error);
    }
  };
  const handleMouseEnter = (video: any, event: React.MouseEvent<HTMLElement>) => {
    setHoveredVideo(video.url);
    setAnchorEl(event.currentTarget); 
  };
  const handleMouseLeave = () => {
    setHoveredVideo(null);
    setAnchorEl(null); 
  };

  return (
    <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        width: "950px",
        borderRadius: "15px",
        padding: "20px",
        maxWidth: "100%",
        overflow: "hidden",
      },
    }}
  >
    <DialogTitle sx={{ fontSize: "1.25rem", fontWeight: "600" }}>
      Add New Playlist Item
    </DialogTitle>
    <DialogContent>
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={formValues}
        render={({ handleSubmit, form, values }) => (
          <form
          onSubmit={async (e) => {
            await handleSubmit(e);
            
            if (values.platform === "S3") {
              await handleSubmitS3(values);
            }
        
            form.reset();
          }}
        >
            {/* Platform Select */}
            <Box sx={{ mb: 2 }}>
              <Field name="platform">
                {({ input }) => (
                  <RffSelect
                    {...input}
                    label="Platform"
                    required
                    formControlProps={{ margin: "normal" }}
                    onChange={(e) => {
                      input.onChange(e);
                      if (e.target.value === "S3") {
                        getS3Data();
                      }
                    }}
                    sx={{
                      backgroundColor: "#f4f6f8",
                      borderRadius: "8px",
                      padding: "8px",
                    }}
                  >
                    <MenuItem value="youtube">YouTube</MenuItem>
                    <MenuItem value="S3">Stock Videos</MenuItem>
                  </RffSelect>
                )}
              </Field>
            </Box>
  
            {/* YouTube Area */}
            {values.platform === "youtube" && (
              <Box sx={{ mb: 2 }}>
                <Field name="url">
                  {({ input, meta }) => (
                    <TextField
                      label="URL"
                      variant="outlined"
                      fullWidth
                      {...input}
                      onBlur={() => {
                        handleScrubVideoDetails(input.value, form);
                        setFormValues((prev) => ({ ...prev, url: input.value }));
                      }}
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      sx={{
                        mb: 2,
                        backgroundColor: "#f4f6f8",
                        borderRadius: "8px",
                        padding: "1px",
                      }}
                    />
                  )}
                </Field>
  
                <Field name="title">
                  {({ input, meta }) => (
                    <TextField
                      label="Title"
                      variant="outlined"
                      fullWidth
                      {...input}
                      value={values.title || videoDetails.title}
                      onChange={(e) => {
                        input.onChange(e);
                        setFormValues((prev) => ({ ...prev, title: e.target.value }));
                      }}
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      sx={{
                        mb: 2,
                        backgroundColor: "#f4f6f8",
                        borderRadius: "8px",
                        padding: "1px",
                      }}
                    />
                  )}
                </Field>
  
                <Field name="description">
                  {({ input, meta }) => (
                    <TextField
                      label="Description"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={3}
                      {...input}
                      value={values.description || videoDetails.description}
                      onChange={(e) => {
                        input.onChange(e);
                        setFormValues((prev) => ({ ...prev, description: e.target.value }));
                      }}
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      sx={{
                        mb: 2,
                        backgroundColor: "#f4f6f8",
                        borderRadius: "8px",
                        padding: "1px",
                      }}
                    />
                  )}
                </Field>
              </Box>
            )}
  
            {/* S3 Video Selection */}
            {values.platform === "S3" && (
              <Box sx={{ mb: 2, p: 4, border: "1px solid #ccc", borderRadius: "8px" }}>
                <Typography variant="h6" sx={{ fontWeight: "600", marginBottom: "10px" }}>
                  Select videos to add to your playlist
                </Typography>
  
                {s3Videos.length > 0 ? (
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      gap: 2,
                      mt: 2,
                    }}
                  >
                    {s3Videos.map((video) => (
                      <Card
                        key={video.url}
                        sx={{
                          cursor: "pointer",
                          position: "relative",
                          borderRadius: "8px",
                          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                          transition: "transform 0.3s ease",
                          "&:hover": {
                            transform: "scale(1.05)",
                            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
                          },
                          border: selectedVideos.includes(video.url)
                            ? "3px solid #4caf50"
                            : "none",
                        }}
                        onMouseEnter={(e) => handleMouseEnter(video, e)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => toggleSelection(video.url)}
                      >
                        {hoveredVideo === video.url ? (
                          <video
                            src={video.url}
                            width="100%"
                            height="120"
                            autoPlay
                            muted
                            loop
                            style={{
                              borderRadius: "8px",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <CardMedia
                            component="img"
                            height="120"
                            image={video.thumbnail}
                            alt={video.title}
                          />
                        )}
  
                   
  
                        <CardContent>
                          <Typography variant="body2" noWrap>
                            {video.title}
                          </Typography>
                        </CardContent>
  
                        {/* Display selection icon */}
                        {selectedVideos.includes(video.url) && (
                          <Box
                            sx={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                              backgroundColor: "rgba(0, 0, 0, 0.6)",
                              borderRadius: "50%",
                              padding: "5px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <CheckCircleIcon sx={{ color: "white" }} />
                          </Box>
                        )}
                      </Card>
                    ))}
                  </Box>
                ) : (
                  <Typography sx={{ mt: 2, color: "gray" }}>No videos found.</Typography>
                )}
              </Box>
            )}
  
            <DialogActions>
              <Button
                onClick={() => {
                  form.reset();
                  onClose();
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained">
                Add
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </DialogContent>
  </Dialog>
  
  );
};

export default PlaylistModal;
