import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import styles from "./PrivacyPolicyModal.module.scss";
import { WarningAmber } from "@mui/icons-material";

const PrivacyPolicyModal = ({
  open,
  onClose,
  content,
  showAcceptButton,
  userId,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleAccept = async () => {
    const { data } = await axios.get("/user");
    const userData = data[1].medicube_user_id;
    if (isChecked) {
      setIsSubmitting(true);
      try {
        await axios.post(
          "document_agreement",
          {
            legal_document_id: 3,
            user_type: "system_user",
            user_id: userData,
            patient_id: 0,
            consent_bundle_id: 0,
          },
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        onClose();
      } catch (error) {
        console.error("Failed to send document agreement:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const logOut = () => {
    Cookies.set("token", "");
    axios.defaults.headers.common.Authorization = "";
    window.location.replace("/login");
  };

  return (
    <>
      <Modal
        open={open}
        onClose={showAcceptButton ? null : onClose}
        disableBackdropClick={showAcceptButton}
        disableEscapeKeyDown={showAcceptButton}
        className={styles.modal}
      >
        <Box className={styles.modalContent}>
          <div dangerouslySetInnerHTML={{ __html: content }} />

          {showAcceptButton ? (
            <div className={styles.actionContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                }
                label="I agree to the Privacy Policy"
              />

              <div style={{ display: "flex", gap: "10px" }}>
                <Button
                  onClick={handleAccept}
                  variant="contained"
                  color="primary"
                  disabled={!isChecked || isSubmitting}
                >
                  Accept
                </Button>

                <Button
                  onClick={handleOpenModal}
                  variant="outlined"
                  color="error"
                >
                  Decline
                </Button>
              </div>
            </div>
          ) : (
            <Button onClick={onClose} variant="contained" color="primary">
              Close
            </Button>
          )}
        </Box>
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: 10, 
            textAlign: "center", 
          }}
        >
          <WarningAmber sx={{ fontSize: 40, color: "orange", mb: 2 }} />
          <Typography id="modal-title" variant="h6" component="h2">
            Are you sure you want to decline?
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            You will no longer be able to access Medicube services until you
            have agreed to the updated terms and conditions.
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 3 }}
          >
            <Button
              onClick={handleCloseModal}
              variant="contained"
              color="secondary"
            >
              Go back
            </Button>
            <Button onClick={logOut} variant="contained" color="error">
              Confirm & Exit
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default PrivacyPolicyModal;
